import { ErrorBoundary } from '@/components';
import { Box, Flex, HomeIcon } from '@blockanalitica/ui';
import * as Sentry from '@sentry/react';
import { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

type PageProps = {
  title?: ReactNode;
  prefix?: string | ReactNode;
  actions?: ReactNode;
  children: ReactNode;
};

export default function Page({ title, prefix, actions, children }: PageProps) {
  const { pathname } = useLocation();

  const crumbs = useMemo(() => {
    const pathArray = pathname.split('/').filter((v) => v.length > 0);
    const crumbsFromPathArray = pathArray.map((subPath, index) => {
      const href = '/' + pathArray.slice(0, index + 1).join('/');
      const text = subPath[0].toUpperCase() + subPath.slice(1);

      return { href, text };
    });

    const crumbsList = [
      { href: '/', text: <HomeIcon size={16} /> },
      ...crumbsFromPathArray
    ];

    return crumbsList;
  }, [pathname]);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
      <Flex flexDirection="column" gap="xs">
        <Flex flexDirection="column" gap="3xs">
          <Flex
            justifyContent="space-between"
            gap="xs-s"
            flexWrap={['wrap', 'wrap', 'nowrap']}>
            <Flex flexDirection="column" gap="3xs">
              {crumbs.length > 1 ? <Breadcrumbs crumbs={crumbs} /> : null}
              <Flex
                justifyContent="flex-start"
                alignItems="center"
                gap="2xs-xs">
                {prefix ? (
                  typeof prefix === 'string' ? (
                    <h1>{prefix}</h1>
                  ) : (
                    <Box maxHeight="3">{prefix}</Box>
                  )
                ) : null}

                {typeof title === 'string' ? <h1>{title}</h1> : title}
              </Flex>
            </Flex>

            {actions ? (
              <Flex
                alignItems="center"
                gap="xs-s"
                flexWrap={['wrap', 'wrap', 'nowrap']}>
                {actions}
              </Flex>
            ) : null}
          </Flex>
        </Flex>

        {children}
      </Flex>
    </Sentry.ErrorBoundary>
  );
}

import { Space } from '@blockanalitica/ui/package/types/src/theme/space';

/* @link https://utopia.fyi/space/calculator?c=320,12,1.2,1240,16,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
const space: Space = {
  '3xs': 'clamp(0.1875rem, 0.1658rem + 0.1087vi, 0.25rem)',
  '2xs': 'clamp(0.375rem, 0.3315rem + 0.2174vi, 0.5rem)',
  xs: 'clamp(0.5625rem, 0.4973rem + 0.3261vi, 0.75rem)',
  s: 'clamp(0.75rem, 0.663rem + 0.4348vi, 1rem)',
  m: 'clamp(1.125rem, 0.9946rem + 0.6522vi, 1.5rem)',
  l: 'clamp(1.5rem, 1.3261rem + 0.8696vi, 2rem)',
  xl: 'clamp(2.25rem, 1.9891rem + 1.3043vi, 3rem)',
  '2xl': 'clamp(3rem, 2.6522rem + 1.7391vi, 4rem)',
  '3xl': 'clamp(4.5rem, 3.9783rem + 2.6087vi, 6rem)',
  /* One-up pairs */
  '3xs-2xs': 'clamp(0.1875rem, 0.0788rem + 0.5435vi, 0.5rem)',
  '2xs-xs': 'clamp(0.375rem, 0.2446rem + 0.6522vi, 0.75rem)',
  'xs-s': 'clamp(0.5625rem, 0.4103rem + 0.7609vi, 1rem)',
  's-m': 'clamp(0.75rem, 0.4891rem + 1.3043vi, 1.5rem)',
  'm-l': 'clamp(1.125rem, 0.8207rem + 1.5217vi, 2rem)',
  'l-xl': 'clamp(1.5rem, 0.9783rem + 2.6087vi, 3rem)',
  'xl-2xl': 'clamp(2.25rem, 1.6413rem + 3.0435vi, 4rem)',
  '2xl-3xl': 'clamp(3rem, 1.9565rem + 5.2174vi, 6rem)'
};

export default space;

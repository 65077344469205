import {
  Flex,
  FlexProps,
  SearchIcon,
  useDebounceCallback
} from '@blockanalitica/ui';
import { ChangeEvent, useCallback, useState } from 'react';
import { styled, useTheme } from 'styled-components';

export interface SearchInputProps extends Partial<FlexProps> {
  onValueChange: (value: string) => void;
  defaultValue?: string;
  iconSize?: number;
  debounceTime?: number;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

export default function SearchInput({
  onValueChange,
  defaultValue = '',
  iconSize = 16,
  debounceTime = 400,
  inputProps,
  ...rest
}: SearchInputProps) {
  const [value, setValue] = useState(defaultValue);
  const onChangeCallback = useDebounceCallback(onValueChange, debounceTime);
  const theme = useTheme();

  const onChange = useCallback(
    (value: string) => {
      setValue(value);
      onChangeCallback(value);
    },
    [onChangeCallback]
  );

  return (
    <Flex
      borderRadius="-4"
      backgroundColor="background"
      alignItems="center"
      {...rest}>
      <StyledSearchIcon color={theme.colors.muted} size={iconSize} />
      <StyledInput
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        value={value}
        {...inputProps}
      />
    </Flex>
  );
}

const StyledSearchIcon = styled(SearchIcon)`
  paddingleft: ${(props) => props.theme.sizes['-1']};
`;

const StyledInput = styled.input`
  flex: 1;
  padding: var(--space-2xs);
  color: var(--color-text);
  font-size: var(--size--1);
  border: none;
  background-color: transparent;

  &::placeholder {
    color: var(--color-muted);
  }

  &:focus {
    outline: none;
    border-color: var(--color-onSurface200);
  }
`;

import {
  CopyButton,
  DebankPictogram,
  EtherScanPictogram,
  Flex,
  Text,
  ZapperPictogram,
  addressShortener
} from '@blockanalitica/ui';
import { useMemo } from 'react';
import CryptoIcon from '../CryptoIcon';

export type TSize = 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge';

export type TUrl = 'ethereum' | 'sepolia';

type WalletAddressOptions = {
  name?: string;
  identicon?: boolean;
  inline?: boolean;
  size?: TSize;
  showCopyBtn?: boolean;
  showEthScanLink?: boolean;
  showDebankLink?: boolean;
  showZapperLink?: boolean;
  network?: TUrl;
};

type WalletAddressProps = {
  address: string;
  options: WalletAddressOptions;
};

const addressSizeMap = {
  xSmall: '2',
  small: '3',
  medium: '4',
  large: '5',
  xLarge: '6'
};

const nameSizeMap = {
  xSmall: '1',
  small: '1',
  medium: '2',
  large: '3',
  xLarge: '4'
};

const buttonsSizeMap = {
  xSmall: 10,
  small: 12,
  medium: 12,
  large: 14,
  xLarge: 16
};

const iconSizeMap = {
  xSmall: '2',
  small: '3',
  medium: '4',
  large: '5',
  xLarge: '6'
};

const urlMap = {
  ethereum: 'https://etherscan.io/address/',
  sepolia: 'https://sepolia.etherscan.io/address/'
};

export default function WalletAddress({
  address,
  options: incomingOptions
}: WalletAddressProps) {
  const options = useMemo(
    () => ({
      identicon: true,
      inline: false,
      size: 'medium',
      showCopyBtn: false,
      showEthScanLink: false,
      showDebankLink: false,
      showZapperLink: false,
      network: 'ethereum',
      ...incomingOptions
    }),
    [incomingOptions]
  );

  return (
    <Flex gap="xs" alignItems="center">
      {options.identicon && !options.inline ? (
        <CryptoIcon address={address} size={iconSizeMap[options.size]} />
      ) : null}

      <Flex
        flexDirection={options.inline ? 'row' : 'column'}
        gap={options.inline ? '2xs' : '3xs'}
        alignItems={options.inline ? 'baseline' : undefined}>
        <Flex gap="2xs" alignItems="baseline">
          <Flex flexDirection="column" gap="3xs">
            <Text size={addressSizeMap[options.size as TSize]}>
              {addressShortener({ address })}
            </Text>

            {options.name ? (
              <Text color="muted" size={nameSizeMap[options.size as TSize]}>
                {options.name}
              </Text>
            ) : null}
          </Flex>

          {!options.showCopyBtn && options.inline ? (
            <CopyButton
              size={buttonsSizeMap[options.size as TSize]}
              value={address}
            />
          ) : null}
        </Flex>

        <Flex gap="2xs">
          {options.showCopyBtn && !options.inline ? (
            <CopyButton
              size={buttonsSizeMap[options.size as TSize]}
              value={address}
            />
          ) : null}

          {options.showEthScanLink ? (
            <a
              href={urlMap[options.network as TUrl] + address}
              target="_blank"
              rel="noreferrer">
              <EtherScanPictogram
                size={buttonsSizeMap[options.size as TSize]}
              />
            </a>
          ) : null}

          {options.showDebankLink ? (
            <a
              href={'https://debank.com/profile/' + address}
              target="_blank"
              rel="noreferrer">
              <DebankPictogram size={buttonsSizeMap[options.size as TSize]} />
            </a>
          ) : null}

          {options.showZapperLink ? (
            <a
              href={'https://zapper.xyz/account/' + address}
              target="_blank"
              rel="noreferrer">
              <ZapperPictogram size={buttonsSizeMap[options.size as TSize]} />
            </a>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
}

import { Box, Flex, FlexProps } from '@blockanalitica/ui';
import { ReactNode, useCallback } from 'react';
import styled, { css } from 'styled-components';

export interface ItemProps extends FlexProps {
  $active: boolean;
}
const Wrapper = styled(Flex)<FlexProps>`
  overflow-y: auto;
  padding: 1px;
`;
const Container = styled(Flex)<FlexProps>`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    mix-blend-mode: overlay;

    ${(props) => css`
      outline: solid 1px ${props.theme.colors.white70};
      border-radius: ${props.theme.sizes[-1]};
    `}
  }
`;

const Item = styled(Box)<ItemProps>`
  cursor: pointer;
  position: relative;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    mix-blend-mode: overlay;
    border-right: solid 1px ${(props) => props.theme.colors.white70};
  }

  &:hover:after {
    background: ${(props) => props.theme.colors.white25};
  }

  ${(props) => css`
    &:first-child:after {
      border-top-left-radius: ${props.theme.sizes[-1]};
      border-bottom-left-radius: ${props.theme.sizes[-1]};
    }

    &:last-child:after {
      border-top-right-radius: ${props.theme.sizes[-1]};
      border-bottom-right-radius: ${props.theme.sizes[-1]};
    }
  `}

  ${(props) =>
    props.$active
      ? css`
          color: ${props.theme.colors.text};

          &:after {
            background: ${props.theme.colors.white70};
          }
        `
      : null}
`;

export type DisplaySwitchData = {
  key: string;
  value: ReactNode;
};

export interface DisplaySwitchProps
  extends Omit<Partial<FlexProps>, 'onChange'> {
  data: DisplaySwitchData[];
  activeOption: string;
  onChange: (optionKey: string) => void;
}

export default function DisplaySwitch({
  data,
  activeOption,
  onChange,
  ...rest
}: DisplaySwitchProps) {
  const onOptionClick = useCallback(
    (optionKey: string) => {
      if (onChange) {
        onChange(optionKey);
      }
    },
    [onChange]
  );

  return (
    <Wrapper {...rest}>
      <Container alignSelf="flex-start">
        {data.map((option) => (
          <Item
            role="button"
            key={option.key}
            onClick={() => onOptionClick(option.key)}
            padding="xs s"
            color="muted"
            $active={option.key === activeOption}>
            {option.value}
          </Item>
        ))}
      </Container>
    </Wrapper>
  );
}

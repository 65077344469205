import { ReactNode, useCallback } from 'react';
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
  useSearchParams
} from 'react-router-dom';

type LinkProps = ReactRouterLinkProps & {
  children?: ReactNode;
  forwardKeys?: string[];
};

export default function Link({
  children,
  to,
  forwardKeys = ['days_ago'],
  ...rest
}: LinkProps) {
  const [searchParams] = useSearchParams();

  const constructToPath = useCallback(() => {
    if (forwardKeys && forwardKeys.length > 0) {
      const toPath = typeof to === 'object' ? to.pathname : to;
      const toHasQueryParams = toPath.includes('?');

      const params = forwardKeys.map((key) =>
        searchParams.get(key) ? `${key}=${searchParams.get(key)}` : null
      );

      const prefix = toHasQueryParams ? '&' : '?';
      const search = `${prefix}${params.join('&')}`;

      return typeof to === 'object'
        ? { ...to, search: search }
        : `${toPath}${search}`;
    }
    return to;
  }, [forwardKeys, to, searchParams]);

  return (
    <ReactRouterLink {...rest} to={constructToPath()}>
      {children}
    </ReactRouterLink>
  );
}

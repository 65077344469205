export const colors = {
  background: '#222831',
  surface: '#2c3153',
  onSurface: '#31363F',
  onSurface100: '#2f2f3d',
  onSurface200: '#2e383f',
  onSurface400: '#9fafb9',
  onSurface500: '#d1dee6',
  onSurface600: '#f9f9f9',
  muted: '#A9A9A9',
  muted40: '#A9A9A9',
  muted10: '#A9A9A9',
  text: '#ffffff',
  gray: '#7e7e88',
  // primary is currently same as link
  primary: '#76ABAE',
  secondary: '#231536',
  success: '#1dd9ba',
  success200: '#e8fcee',
  successGraph: '#33a386',
  error: '#ff6d6d',
  error200: '#f8e7ec',
  errorGraph: '#b44451',
  warning: '#ff832b',
  warning200: '#fcede8',
  warningGraph: '#c57a3a',
  notice: '#e0f1f9',
  link: '#76ABAE',
  linkHover: '#C8DDDF',
  bgGradientStart: '#2a197d',
  bgGradientMiddle: '#4331e9',
  bgGradientEnd: '#6a6cfb',
  transparent: 'transparent',
  border: 'rgba(255, 255, 255, 0.4)',
  border15: 'rgba(255, 255, 255, 0.15)',
  btnGradientDark: '#29265e',
  btnGradientLight: '#35337c',
  btnGradientDarkHover: '#26225b',
  btnGradientLightHover: '#3a2f77',
  neutral: 'rgba(255, 255, 255, 0.15)',
  tooltip: '#1c1828',
  white: 'rgba(255, 255, 255)',
  white25: 'rgba(255, 255, 255, 0.25)',
  white40: 'rgba(255, 255, 255, 0.4)',
  white70: 'rgba(255, 255, 255, 0.7)',
  white85: 'rgba(255, 255, 255, 0.85)',
  black23: 'rgba(0, 0, 0, 0.23)',
  black65: 'rgba(0, 0, 0, 0.65)',
  chartLine: '#76ABAE',
  chartIndicatorLine: '#76ABAE',
  chartUp: '#45C8E5',
  chartDown: '#A979D8',
  skeletonBaseColor: '#31363F',
  tableRowHover: '#31363F',
  tableStickyBg: '#31363F'
};

import { Page, WalletAddress } from '@/components';

import { useApi } from '@/api';
import { PageSkeleton } from '@/components';
import { useSearchParams } from '@/hooks';
import { Flex, KpiCard } from '@blockanalitica/ui';
import { useParams } from 'react-router-dom';
import GemEventsTable from './GemEventsTable';

export default function WalletPage() {
  const ilk = 'LITE-PSM-USDC-A';
  const { walletAddress } = useParams();
  const [daysAgo] = useSearchParams('days_ago', '1');
  const [data, error] = useApi({
    url: `/groups/stablecoins/ilks/${ilk}/gems/wallets/${walletAddress}/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }
  if (data) {
    return (
      <Page>
        <Flex>
          <WalletAddress
            address={data.wallet_address}
            options={{
              name: data.wallet_name,
              showCopyBtn: true,
              showEthScanLink: true,
              showDebankLink: true,
              size: 'large'
            }}
          />
        </Flex>
        <Flex gap={['m', 's']} flexDirection={['column', 'row']}>
          <KpiCard
            title="Net Balance"
            data={data}
            field="balance"
            options={{
              valueOptions: {
                currencyValueOptions: {
                  currency: 'DAI',
                  iconSize: '1'
                }
              },
              variants: {
                kpiCardValue: 'small',
                kpiHeader: 'small'
              }
            }}
          />
          <KpiCard
            title="Total Volume"
            data={data}
            field="volume"
            options={{
              valueOptions: {
                currencyValueOptions: {
                  currency: 'DAI',
                  iconSize: '1'
                }
              },
              variants: {
                kpiCardValue: 'small',
                kpiHeader: 'small'
              }
            }}
          />
          <KpiCard
            title="Total Fees"
            data={data}
            field="fee_balance"
            options={{
              valueOptions: {
                currencyValueOptions: {
                  currency: 'DAI',
                  iconSize: '1'
                }
              },
              variants: {
                kpiCardValue: 'small',
                kpiHeader: 'small'
              }
            }}
          />
        </Flex>
        <GemEventsTable ilk={ilk} walletAddress={walletAddress} />
        {/* <Activity
          url={`/groups/stablecoins/ilks/${ilk}/activity/`}
          linkTo="activity"
        /> */}
      </Page>
    );
  }
  return <PageSkeleton />;
}

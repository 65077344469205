import TimeFilterComponent from './TimeFilterComponent';

const defaultOptions = {
  '30': '1M',
  '90': '3M',
  '365': '1Y',
  '9999': 'MAX'
};

type TimeFilterProps = {
  activeOption: string;
  options?: Record<string, string>;
  onChange: (value: string) => void;
};

export default function ChartTimeFilter({
  activeOption = '30',
  options = defaultOptions,
  onChange
}: TimeFilterProps) {
  return (
    <TimeFilterComponent
      activeOption={activeOption}
      options={options}
      onChange={onChange}
    />
  );
}

import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function heatmapRowLabel(theme: Theme): ComponentStyle {
  return {
    componentName: 'heatmapRowLabel',
    baseStyle: {
      color: theme.colors.muted,
      fontSize: theme.sizes['-1']
    }
  };
}

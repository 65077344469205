import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function listHeader(theme: Theme): ComponentStyle {
  return {
    componentName: 'listHeader',
    baseStyle: {
      paddingBottom: theme.space.s,
      borderBottom: `1px solid ${theme.colors.border15}`,
      fontSize: theme.sizes['2'],
      fontWeight: 500
    }
  };
}

import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function heatmapCell(theme: Theme): ComponentStyle {
  return {
    componentName: 'heatmapCell',
    baseStyle: {
      width: theme.sizes['0'],
      height: theme.sizes['0'],
      borderRadius: '2px',
      position: 'relative',
      backgroundColor: theme.colors.muted10,
      outline: `1px solid ${theme.colors.onSurface200}`
    }
  };
}

import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function card(): ComponentStyle {
  return {
    componentName: 'card',
    baseStyle: {
      flex: '1'
    },
    variants: {}
  };
}

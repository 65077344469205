import { useApi } from '@/api';
import { ChartTimeFilter, DisplaySwitch } from '@/components';
import { ChartSkeleton, Flex } from '@blockanalitica/ui';
import { useMemo, useState } from 'react';
import HistoricGraphs from './HistoricGraphs';
import VolumeChart from './VolumeChart';
// import HistoricGraphs from './HistoricGraphs';

export default function HistoricTabs({ ilk }) {
  ilk = 'LITE-PSM-USDC-A';
  const [activeDisplayOption, setActiveDisplayOption] = useState('reserve');
  const [chartDaysAgo, setChartDaysAgo] = useState('30');
  const displayOptions = useMemo(
    () => [
      { key: 'reserve', value: 'USDC' },
      { key: 'dai', value: 'DAI' },
      // { key: 'unique_wallets', value: 'Unique addresses' },
      // { key: 'buf', value: 'Buffer' },
      { key: 'volume', value: 'Volume' }
    ],
    []
  );

  const [data, error] = useApi({
    url: `/groups/stablecoins/ilks/${ilk}/snapshots/`,
    queryParams: { days_ago: chartDaysAgo }
  });

  if (error) {
    throw error;
  }
  const timeFilter = useMemo(
    () => (
      <ChartTimeFilter activeOption={chartDaysAgo} onChange={setChartDaysAgo} />
    ),
    [chartDaysAgo]
  );

  if (data) {
    return (
      <Flex flexDirection="column" gap="xs">
        <DisplaySwitch
          data={displayOptions}
          activeOption={activeDisplayOption}
          onChange={setActiveDisplayOption}
        />
        {activeDisplayOption === 'volume' ? (
          <VolumeChart
            ilk={ilk}
            timeFilter={timeFilter}
            chartDaysAgo={chartDaysAgo}
          />
        ) : (
          <HistoricGraphs
            data={data}
            type={activeDisplayOption}
            timeFilter={timeFilter}
          />
        )}
      </Flex>
    );
  }

  return <ChartSkeleton />;
}

import { BaseTheme as Theme } from '@blockanalitica/ui/package/types/src/theme/baseTheme';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function headerCell(theme: Theme): ComponentStyle {
  return {
    componentName: 'headerCell',
    baseStyle: {
      borderBottom: `1px solid ${theme.colors.border15}`,
      padding: `${theme.space['xs']} ${theme.space['xs']} ${theme.space['xs']} ${theme.space['xs']}`
    }
  };
}

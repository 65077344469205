import { useApi } from '@/api';
import { CryptoIcon, Link, WalletAddress } from '@/components';
import { LinkButton } from '@/components/forms';

import {
  CurrencyValue,
  DataTable,
  Flex,
  Heading,
  RelativeDateTime,
  TableSkeleton,
  useTableProps
} from '@blockanalitica/ui';
import {
  TableColumn,
  TableData
} from '@blockanalitica/ui/package/types/src/components/molecules/DataTable';

export default function GemEventsTable({ ilk }) {
  const { pagination, sorting } = useTableProps({
    pageSize: 5,
    initialSorting: '-order_index',
    filtersDebounceTime: 50
  });
  const [data, error] = useApi({
    url: `/groups/stablecoins/ilks/${ilk}/gems/events/`,
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      order: sorting.currentSorting
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const columns: TableColumn[] = [
      {
        header: 'Wallet Address',
        getCell: (row) => (
          <WalletAddress
            address={row.wallet_address}
            options={{ size: 'xSmall', name: row.wallet_name }}
          />
        ),
        cellAlign: 'flex-start',
        headerAlign: 'flex-start',
        columnSize: ['minmax(100px, auto)']
      },
      {
        header: 'Event',
        getCell: (row) => row.event,
        // getCellBottom: (row) => (
        //   <Flex gap={['xs', '3xs']} color="muted" fontSize="-2">
        //     {row.event === 'BuyGem' ? (
        //       <>
        //         DAI <ArrowRightIcon size="-1" /> USDC
        //       </>
        //     ) : (
        //       <>
        //         USDC <ArrowRightIcon size="-1" /> DAI
        //       </>
        //     )}
        //   </Flex>
        // ),
        cellAlign: 'flex-start',
        headerAlign: 'flex-start'
      },
      {
        header: (
          <Flex
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="3xs">
            Amount
            <CryptoIcon name="DAI" size="-1" />
          </Flex>
        ),
        getCell: (row) => (
          <CurrencyValue
            value={row.dai_amount}
            options={{ trend: true, iconSize: '-1' }}
          />
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'dai_amount'
      },

      {
        header: (
          <Flex
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="3xs">
            Amount
            <CryptoIcon name="USDC" size="-1" />
          </Flex>
        ),
        getCell: (row) => (
          <CurrencyValue
            value={row.usdc_amount}
            options={{ trend: true, iconSize: '-1' }}
          />
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'usdc_amount'
      },
      {
        header: 'Time',
        getCell: (row) => (
          <Flex gap={['xs', '3xs']} color="muted">
            <RelativeDateTime value={row.datetime} />
          </Flex>
        ),

        sortingField: 'order_index',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
    return (
      <>
        <Flex gap="s" justifyContent="space-between" alignItems="center">
          <Heading size="3">Recent activity</Heading>
          <LinkButton
            size="small"
            to="activity"
            variant="secondary"
            height="1.2rem">
            view all
          </LinkButton>
        </Flex>
        <DataTable
          rows={data['results']}
          columns={columns}
          getKey={(row) => `${row.order_index}`}
          rowLinkProps={{
            to: (row: TableData) => `/wallets/${row.wallet_address}`,
            linkComponent: Link
          }}
        />
      </>
    );
  }
  return <TableSkeleton />;
}

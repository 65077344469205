import { tokensMapping } from '@/utils/tokens';
import { BarChart, CurrencyValue, formatNumber } from '@blockanalitica/ui';
import {
  SimpleChartData,
  SimpleChartDataset
} from '@blockanalitica/ui/package/types/src/components/charts/SimpleChart';
import { ReactNode, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';

export default function HistoricGraphs({
  data,
  type
}: {
  data: SimpleChartData[];
  type: string;
  timeFilter: ReactNode;
}) {
  const theme = useTheme();

  const [datasets, chartType] = useMemo(() => {
    let datasets: SimpleChartDataset[];
    let chartType: string;
    if (type === 'volume') {
      chartType = 'bar';
      datasets = [
        {
          xField: 'date',
          yField: 'buy_volume',
          label: 'BuyGem',
          backgroundColor: theme.colors.chartUp
        },
        {
          xField: 'date',
          yField: 'sell_volume',
          label: 'SellGem',
          backgroundColor: theme.colors.chartDown
        }
      ];
    } else {
      chartType = 'line';
      datasets = [
        {
          xField: 'datetime',
          yField: type
        }
      ];
    }

    return [datasets, chartType];
  }, [type, theme.colors]);

  const valueFormatter = useCallback(
    (value: number) => {
      if (chartType === 'bar') {
        return (
          <CurrencyValue
            value={value}
            options={{ currency: tokensMapping('DAI'), iconSize: '-1' }}
          />
        );
      } else {
        switch (type) {
          default:
            return (
              <CurrencyValue
                value={value}
                options={{
                  currency: 'DAI',
                  iconSize: '2'
                }}
              />
            );
        }
      }
    },
    [type, chartType]
  );

  const yScaleValueFormatter = useCallback((value: number) => {
    return formatNumber(value);
  }, []);

  return (
    <>
      <BarChart
        key={type}
        data={data}
        datasets={datasets}
        valueFormatter={valueFormatter}
        yScaleValueFormatter={yScaleValueFormatter}
      />
    </>
  );
}

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    body {
        color: ${(props) => props.theme.colors.text};
        font-size: ${(props) => props.theme.sizes[0]};
        line-height: 1;
        font-weight: 400;
        background-color: ${(props) => props.theme.colors.background};
        transition: background 0.3s;
        margin: 0;
        min-height: 100vh;
        font-family: 'Plus Jakarta Sans', sans-serif;
    }

    a {
        text-decoration: none;
        color: ${(props) => props.theme.colors.link};

        &:hover {
            color: ${(props) => props.theme.colors.linkHover};
        }
    }

    p {
        margin: 0;
    }
`;

import { useApi } from '@/api';

import { tokensMapping } from '@/utils/tokens';
import {
  BarChart,
  ChartSkeleton,
  CurrencyValue,
  formatNumber
} from '@blockanalitica/ui';
import { SimpleChartDataset } from '@blockanalitica/ui/package/types/src/components/charts/SimpleChart';
import { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';

export default function VolumeChart({
  ilk,
  chartDaysAgo,
  timeFilter
}: {
  ilk: string;
}) {
  const type = 'volume';
  const [data, error] = useApi({
    url: `/groups/stablecoins/ilks/${ilk}/snapshots/`,
    queryParams: {
      days_ago: chartDaysAgo
    }
  });

  if (error) {
    throw error;
  }

  const theme = useTheme();

  const [datasets, chartType] = useMemo(() => {
    let datasets: SimpleChartDataset[];
    let chartType: string;
    if (type === 'volume') {
      chartType = 'bar';
      datasets = [
        {
          xField: 'date',
          yField: 'buy_volume',
          label: 'BuyGem',
          backgroundColor: theme.colors.chartUp
        },
        {
          xField: 'date',
          yField: 'sell_volume',
          label: 'SellGem',
          backgroundColor: theme.colors.chartDown
        }
      ];
    } else {
      chartType = 'line';
      datasets = [
        {
          xField: 'datetime',
          yField: type
        }
      ];
    }

    return [datasets, chartType];
  }, [type, theme.colors]);

  const valueFormatter = useCallback(
    (value: number) => {
      if (chartType === 'bar') {
        return (
          <CurrencyValue
            value={value}
            options={{ currency: tokensMapping('DAI'), iconSize: '-1' }}
          />
        );
      } else {
        switch (type) {
          default:
            return (
              <CurrencyValue
                value={value}
                options={{
                  currency: 'DAI',
                  iconSize: '3'
                }}
              />
            );
        }
      }
    },
    [type, chartType]
  );

  const yScaleValueFormatter = useCallback((value: number) => {
    return formatNumber(value);
  }, []);

  if (data) {
    return (
      <>
        <BarChart
          key={type}
          data={data}
          datasets={datasets}
          config={{
            options: {
              scales: {
                x: {
                  type: 'time',
                  time: {
                    unit: 'week'
                  }
                }
              }
            }
          }}
          valueFormatter={valueFormatter}
          yScaleValueFormatter={yScaleValueFormatter}
          timeFilter={timeFilter}
        />
      </>
    );
  }

  return <ChartSkeleton />;
}

import { useApi } from '@/api';
import { Link, WalletAddress } from '@/components';

import {
  CryptoIcon,
  CurrencyValue,
  DataTable,
  Flex,
  RelativeDateTime,
  TableSkeleton,
  useTableProps
} from '@blockanalitica/ui';
import {
  TableColumn,
  TableData
} from '@blockanalitica/ui/package/types/src/components/molecules/DataTable';

export default function WalletsTable({ ilk }) {
  const { pagination, sorting } = useTableProps({
    pageSize: 10,
    initialSorting: '-balance',
    filtersDebounceTime: 50
  });
  const [data, error] = useApi({
    url: `/groups/stablecoins/ilks/${ilk}/gems/wallets/`,
    queryParams: {
      p: pagination.currentPage,
      p_size: pagination.pageSize,
      order: sorting.currentSorting
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const columns: TableColumn[] = [
      {
        header: 'Wallet Address',
        getCell: (row) => (
          <WalletAddress
            address={row.wallet_address}
            options={{ size: 'xSmall', name: row.wallet_name }}
          />
        ),
        cellAlign: 'flex-start',
        headerAlign: 'flex-start',
        columnSize: ['minmax(100px, auto)']
      },
      {
        header: (
          <Flex
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="3xs">
            Net Balance
            <CryptoIcon name="DAI" size="-1" />
          </Flex>
        ),
        getCell: (row) => <CurrencyValue value={row.balance} options={{}} />,
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'balance'
      },
      {
        header: (
          <Flex
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="3xs">
            Volume
            <CryptoIcon name="DAI" size="-1" />
          </Flex>
        ),
        getCell: (row) => <CurrencyValue value={row.volume} />,
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'volume'
      },

      {
        header: 'Latest Activity',
        getCell: (row) => (
          <Flex gap={['xs', '3xs']} color="muted">
            <RelativeDateTime value={row.datetime} />
          </Flex>
        ),

        sortingField: 'order_index',
        cellAlign: 'flex-end',
        headerAlign: 'flex-end'
      }
    ];
    return (
      <>
        <DataTable
          rows={data['results']}
          columns={columns}
          getKey={(row) => `${row.order_index}`}
          rowLinkProps={{
            to: (row: TableData) => `${row.wallet_address}`,
            linkComponent: Link
          }}
          sorting={sorting}
          pagination={{
            ...pagination,
            totalRecords: data.count
          }}
        />
      </>
    );
  }
  return <TableSkeleton />;
}

import { ReactNode, useMemo, useState } from 'react';

import {
  Box,
  ThemedComponentWrapper,
  ThemedComponentWrapperProps
} from '@blockanalitica/ui';
import { Size } from '@blockanalitica/ui/package/types/src/theme/types';
import makeBlockie from 'ethereum-blockies-base64';
import styled from 'styled-components';

export type IconSize = 'xSmall' | 'small' | 'medium' | 'large';
export type Extension = 'svg' | 'png';

type CryptoIconOptions = {
  fallback?: ReactNode;
  opacity?: number;
};

interface CryptoIconProps
  extends Omit<ThemedComponentWrapperProps, 'name' | 'size'> {
  name: string;
  address?: string;
  network?: string;
  variant?: string;
  size?: Size | string | number;
  options?: CryptoIconOptions;
  extension?: Extension;
}

const baseUrl = 'https://icons.blockanalitica.com';

const BlockieImg = styled.img<{ size: string }>`
  border-radius: 50%;

  ${(props) => {
    switch (props.size) {
      case '2':
        return `
          width: ${props.theme.sizes[2]};
          height: ${props.theme.sizes[2]};
        `;
      case '3':
        return `
          width: ${props.theme.sizes[3]};
          height: ${props.theme.sizes[3]};
        `;
      case '4':
        return `
          width: ${props.theme.sizes[4]};
          height: ${props.theme.sizes[4]};
        `;
      case '5':
        return `
          width: ${props.theme.sizes[5]};
          height: ${props.theme.sizes[5]};
        `;
      case '6':
        return `
          width: ${props.theme.sizes[6]};
          height: ${props.theme.sizes[6]};
        `;
      default:
        return `
          width: ${props.theme.sizes[4]};
          height: ${props.theme.sizes[4]};
        `;
    }
  }}
`;

export default function CryptoIcon({
  name,
  address,
  network = 'ethereum',
  variant,
  size = '2',
  options,
  extension = 'svg',
  ...rest
}: CryptoIconProps) {
  const [error, setError] = useState<Error | null>(null);

  const mergedOptions = useMemo(
    () => ({
      opacity: 1,
      ...options
    }),
    [options]
  );
  const src = useMemo(() => {
    if (address) {
      const variantSuffix = variant ? `-${variant}` : '';
      return `${baseUrl}/tokens/${network}/${address.toLowerCase()}${variantSuffix}/icon.${extension}`;
    }

    return `${baseUrl}/crypto/${extension}/${name.toLowerCase()}.${extension}`;
  }, [name, extension, address, network, variant]);

  if (error) {
    console.error(error);
    return (
      <ThemedComponentWrapper {...rest} name="cryptoIcon">
        <BlockieImg alt="address" src={makeBlockie(address)} size={size} />
      </ThemedComponentWrapper>
    );
  }

  return (
    <ThemedComponentWrapper
      opacity={mergedOptions.opacity}
      {...rest}
      name="cryptoIcon">
      <Box
        as="img"
        alignSelf="center"
        //@ts-ignore
        src={src}
        alt={name}
        width={typeof size === 'number' ? size + 'px' : size}
        height={typeof size === 'number' ? size + 'px' : size}
        loading="lazy"
        onError={() => setError(new Error())}
      />
    </ThemedComponentWrapper>
  );
}

import { useApi } from '@/api';
import { Link } from '@/components';
import { tokensMapping } from '@/utils/tokens';
import { Flex, KpiCard, KpiSkeleton, LinkWrapper } from '@blockanalitica/ui';

export default function KpiParameters({ daysAgo }) {
  const [data, error] = useApi({
    url: '/groups/stablecoins/ilks/LITE-PSM-USDC-A/parameters/',
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Flex flexDirection="column" gap="m">
        <Flex flexDirection={['column', 'row']} gap={['m', 's']}>
          <LinkWrapper
            to="wallets"
            // @ts-ignore
            as={Link}
            variant="kpiCardLink">
            <KpiCard
              title="Unique addresses"
              data={data}
              field="unique_wallets"
              options={{
                linkArrow: true,
                variants: {
                  kpiHeader: 'small',
                  kpiCardValue: 'small'
                }
              }}
            />
          </LinkWrapper>
          <KpiCard
            title="Buffer"
            data={data}
            field="buf"
            options={{
              valueOptions: {
                currencyValueOptions: {
                  currency: tokensMapping('DAI'),
                  iconSize: '0',
                  notation: 'compact'
                }
              },
              variants: {
                kpiHeader: 'small',
                kpiCardValue: 'small'
              }
            }}
          />
          <KpiCard
            title="Fee in"
            data={data}
            field="tin"
            options={{
              percentage: true,
              variants: {
                valuePercentageValue: 'dynamic',
                kpiHeader: 'small',
                kpiCardValue: 'small'
              }
            }}
          />

          <KpiCard
            title="Fee Out"
            data={data}
            field="tout"
            options={{
              percentage: true,
              variants: {
                valuePercentageValue: 'dynamic',
                kpiHeader: 'small',
                kpiCardValue: 'small'
              }
            }}
          />
        </Flex>
      </Flex>
    );
  }

  return <KpiSkeleton />;
}

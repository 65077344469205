import { RouterProvider } from 'react-router-dom';
import { initialize, ThemeProvider } from '@blockanalitica/ui';
import router from './router';
import { theme, GlobalStyle } from './theme';

initialize({ intl: { timezone: 'UTC' } });

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

import { useApi } from '@/api';
import { ChartSkeleton, Flex, Heading, Text } from '@blockanalitica/ui';
import TopWallets from './TopWallets';

export default function HistoricTabs({ ilk }: { ilk: string }) {
  const chartDaysAgo = 1;

  const [data, error] = useApi({
    url: `/groups/stablecoins/ilks/${ilk}/top-wallets/`,
    queryParams: {
      days_ago: chartDaysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Flex flexDirection="column" gap="xs">
        <Heading size="3">
          Top 10 Wallets by volume
          <Text color="muted" size="2">
            (excluding Maker){' '}
          </Text>
        </Heading>
        <TopWallets data={data} />
      </Flex>
    );
  }

  return <ChartSkeleton />;
}

import apiFetcher, { apiFetcherArgs } from '@/api/apiFetcher';
import useSWR from 'swr';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useApi<T = any>(
  args: apiFetcherArgs
): [T | undefined, Error | undefined] {
  const { data, error } = useSWR<T, Error, apiFetcherArgs>(args, apiFetcher);

  return [data, error];
}

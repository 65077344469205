export function tokensMapping(symbol: string) {
  const mapping = {
    DAI: { name: 'DAI', address: '0x6b175474e89094c44da98b954eedeac495271d0f' },
    USDS: {
      name: 'USDS',
      address: '0x798f111c92e38f102931f34d1e0ea7e671bdbe31'
    },
    USDC: {
      name: 'USDC',
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
    }
  };

  return mapping[symbol];
}

import { Page } from '@/components';

import { useSearchParams } from '@/hooks';
import GemEventsTable from './GemEventsTable';
import HistoricTabs from './HistoricTabs';
import KpiParameters from './KpiParameters';
import TopKpis from './TopKpis';

export default function HomePage() {
  const ilk = 'LITE-PSM-USDC-A';
  const [daysAgo] = useSearchParams('days_ago', '1');

  return (
    <Page>
      <TopKpis daysAgo={daysAgo} />
      <HistoricTabs ilk={ilk} />
      <KpiParameters daysAgo={daysAgo} />
      <GemEventsTable ilk={ilk} />
      {/* <Flex flexDirection={['column', 'row']} gap="m">
        <WalletsTable ilk={ilk} defaultSorting={'balance'} />
        <WalletsTable ilk={ilk} defaultSorting={'-balance'} />
      </Flex> */}
      {/* <Activity
        url={`/groups/stablecoins/ilks/${ilk}/activity/`}
        linkTo="activity"
      /> */}
    </Page>
  );
}

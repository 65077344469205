import { BaseTheme } from '@blockanalitica/ui/package/types/src/theme/baseTheme';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function collapsableSidebar(theme: BaseTheme): ComponentStyle {
  return {
    componentName: 'collapsableSidebar',
    baseStyle: {
      backgroundColor: theme.colors.black65,
      padding: theme.space.s,
      borderLeft: `1px solid ${theme.colors.onSurface}`
    }
  };
}

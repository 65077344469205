import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function listItem(theme: Theme): ComponentStyle {
  return {
    componentName: 'listItem',
    baseStyle: {
      padding: `${theme.space.s} 0`,
      borderBottom: `1px solid ${theme.colors.border15}`,
      color: theme.colors.text
    }
  };
}

import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function linkButton(theme: Theme): ComponentStyle {
  return {
    componentName: 'linkButton',
    baseStyle: {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.sizes['-2'],
      padding: `${theme.space['2xs']} ${theme.space.xs}`,
      color: theme.colors.text,
      backgroundColor: theme.colors.foreground,
      borderRadius: theme.sizes['-4']
    }
  };
}

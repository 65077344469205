import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function cardHeader(theme: Theme): ComponentStyle {
  return {
    componentName: 'cardHeader',
    baseStyle: {
      fontSize: theme.sizes['2'],
      fontWeight: 500,
      display: 'flex'
    }
  };
}

import { NavLink as RouterNavLink } from 'react-router-dom';
import { styled } from 'styled-components';
import { Text } from '@blockanalitica/ui';

type NavLinkProps = {
  isRoot?: boolean;
  isSingleLink?: boolean;
  text: string;
  href: string;
};

const Wrapper = styled(RouterNavLink)<{
  isSingleLink: boolean;
  isRoot: boolean;
}>`
  color: ${(props) => props.theme.colors.muted};
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }

  ${(props) =>
    props.isSingleLink || !props.isRoot
      ? `
      &.active {
        position: relative;
        color: ${props.theme.colors.primary};
        border-radius: ${props.theme.sizes['-4']};
      };`
      : null}

  ${(props) =>
    props.isRoot
      ? `
      color: ${props.theme.colors.text};
      &.active {
        color: ${props.theme.colors.primary};
      }
      text-decoration: none;
      cursor: pointer;`
      : null}
`;

export default function NavLink({
  isRoot = false,
  isSingleLink = false,
  text,
  href
}: NavLinkProps) {
  return (
    <Wrapper to={href} isSingleLink={isSingleLink} isRoot={isRoot}>
      <Text variant={isRoot ? '4' : '3'}>{text}</Text>
    </Wrapper>
  );
}

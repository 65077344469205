import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function chartKpiPoint(theme: Theme): ComponentStyle {
  return {
    componentName: 'chartKpiPoint',
    baseStyle: {
      width: theme.sizes['0'],
      height: theme.sizes['0'],
      borderRadius: '50%'
    }
  };
}

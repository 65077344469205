import { Box, BoxProps } from '@blockanalitica/ui';
import { Link, LinkProps } from 'react-router-dom';

export interface LinkButtonProps extends Partial<BoxProps> {
  to: LinkProps['to'];
  variant?: 'primary' | 'secondary';
  size?: 'default' | 'small';
}

export default function LinkButton({
  children,
  variant = 'primary',
  size = 'default',
  ...rest
}: LinkButtonProps) {
  return (
    <Box
      name="linkButton"
      variant={variant}
      // @ts-ignore
      as={Link}
      padding={size === 'small' ? '2xs xs' : 'xs s'}
      {...rest}>
      {children}
    </Box>
  );
}

import {
  BarChart,
  ChartSkeleton,
  CurrencyValue,
  textShortener
} from '@blockanalitica/ui';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

export default function TopWallets({ data }) {
  const theme = useTheme();
  const datasets = useMemo(() => {
    return [
      {
        xField: 'wallet_address',
        yField: 'volume',
        label: 'Wallets',
        backgroundColor: theme.colors.successGraph
      }
    ];
  }, [theme]);

  const xScaleValueFormatter = useMemo(() => {
    return (value) => {
      return textShortener(data[value]?.wallet_address);
    };
  }, [data]);

  const subValueFormatter = useMemo(() => {
    return function subValueFormatter(value: string) {
      return <>{value}</>;
    };
  }, []);

  const valueFormatter = useMemo(() => {
    return function valueFormatter(value: string) {
      return (
        <CurrencyValue
          value={value}
          options={{
            currency: 'DAI'
          }}
        />
      );
    };
  }, []);

  const config = useMemo(() => {
    return {
      options: {
        scales: {
          x: {
            type: 'category' as const
          }
        }
      }
    };
  }, []);

  if (data) {
    return (
      <BarChart
        data={data}
        datasets={datasets}
        config={config}
        xScaleValueFormatter={xScaleValueFormatter}
        subValueFormatter={subValueFormatter}
        valueFormatter={valueFormatter}
      />
    );
  }
  return <ChartSkeleton />;
}

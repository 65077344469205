import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function cardListHeader(theme: Theme): ComponentStyle {
  return {
    componentName: 'cardListHeader',
    baseStyle: {
      paddingBottom: theme.space['xs-s'],
      borderBottom: `1px solid ${theme.colors.onSurface200}`
    }
  };
}

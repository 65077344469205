import { Box, Flex } from '@blockanalitica/ui';
import _ from 'lodash';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { ResponseError } from '@/api/apiFetcher';

function isApiResponseError(error: unknown): error is ResponseError {
  return _.has(error, 'status');
}

function ErrorBoundary() {
  const error = useRouteError();

  let statusCodeText: string = 'ERROR';
  let message = 'Something went wrong';
  if (isRouteErrorResponse(error)) {
    statusCodeText = error.status as unknown as string;
    message = error.statusText;
  } else if (isApiResponseError(error)) {
    statusCodeText = error.status as unknown as string;
    message = error.message;

    switch (error.status) {
      case 404:
        message = 'Not Found';
        break;
      case 500:
        message = 'Internal Server Error';
        break;
      case 502:
        message = 'Bad Gateway';
        break;
      case 503:
        message = 'Service Unavailable';
        break;
      case 504:
        message = 'Gateway Timeout';
        break;
    }
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      gap="xs"
      height={['90vh', '90vh', '50vh']}>
      <Box borderRight="3px solid primary" fontSize="-4" paddingRight="xs">
        {statusCodeText}
      </Box>
      <div>{message}</div>
    </Flex>
  );
}

export default ErrorBoundary;

import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function select(theme: Theme): ComponentStyle {
  return {
    componentName: 'select',
    variants: {
      timeSelector: {
        padding: `${theme.space['2xs']} ${theme.space['l']} ${theme.space['2xs']} ${theme.space['xs']}`,
        background: theme.colors.onSurface200,
        color: theme.colors.text,
        border: 'none',
        fontSize: theme.sizes['-1']
      }
    }
  };
}

import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function heatmap(theme: Theme): ComponentStyle {
  return {
    componentName: 'heatmap',
    baseStyle: {
      borderCollapse: 'separate',
      borderSpacing: theme.space['3xs'],
      width: 'max-content',
      margin: '0 auto'
    }
  };
}

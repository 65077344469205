import { ErrorBoundary, FixedSidebarLayout as Layout } from '@/components';
import HomePage from '@/pages';
import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';
import ActivityPage from './pages/activity';
import WalletsPage from './pages/wallets';
import WalletPage from './pages/wallets/wallet';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <Layout />,
    errorElement: (
      <Layout>
        <ErrorBoundary />
      </Layout>
    ),
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/activity',
        element: <ActivityPage />
      },
      {
        path: '/wallets',
        element: <WalletsPage />
      },
      {
        path: '/wallets/:walletAddress',
        element: <WalletPage />
      }
    ]
  }
]);

export default router;

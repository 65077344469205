import { tokensMapping } from '@/utils/tokens';
import {
  CurrencyValue,
  LineChart,
  formatDatetime,
  formatNumber
} from '@blockanalitica/ui';
import {
  SimpleChartData,
  SimpleChartDataset
} from '@blockanalitica/ui/package/types/src/components/charts/SimpleChart';
import { ReactNode, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';

export default function HistoricGraphs({
  data,
  type,
  timeFilter
}: {
  data: SimpleChartData[];
  type: string;
  timeFilter: ReactNode;
}) {
  const theme = useTheme();

  const [datasets, chartType] = useMemo(() => {
    let datasets: SimpleChartDataset[];
    let chartType: string;
    if (type === 'volume') {
      chartType = 'bar';
      datasets = [
        {
          xField: 'date',
          yField: 'buy_volume',
          label: 'BuyGem',
          backgroundColor: theme.colors.chartUp
        },
        {
          xField: 'date',
          yField: 'sell_volume',
          label: 'SellGem',
          backgroundColor: theme.colors.chartDown
        }
      ];
    } else {
      chartType = 'line';
      datasets = [
        {
          xField: 'date',
          yField: type
        }
      ];
    }

    return [datasets, chartType];
  }, [type, theme.colors]);

  const valueFormatter = useCallback(
    (value: number) => {
      if (chartType === 'bar') {
        return (
          <CurrencyValue
            value={value}
            options={{ currency: tokensMapping('DAI'), iconSize: '0' }}
          />
        );
      } else {
        switch (type) {
          case 'reserve':
            return (
              <CurrencyValue
                value={value}
                options={{
                  currency: 'USDC',
                  iconSize: '2',
                  notation: 'standard'
                }}
              />
            );
          case 'unique_wallets':
            return formatNumber(value);
          default:
            return (
              <CurrencyValue
                value={value}
                options={{
                  currency: 'DAI',
                  iconSize: '2',
                  notation: 'standard'
                }}
              />
            );
        }
      }
    },
    [type, chartType]
  );

  const stepSize = type === 'unique_wallets' ? 1 : null;

  const subValueFormatter = useCallback((value: number) => {
    return <>{formatDatetime(value)}</>;
  }, []);

  const yScaleValueFormatter = useCallback((value: number) => {
    return formatNumber(value, { maximumFractionDigits: 5 });
  }, []);

  return (
    <>
      <LineChart
        key={type}
        data={data}
        datasets={datasets}
        config={{
          options: {
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'week'
                }
              },
              y: {
                ticks: {
                  stepSize: stepSize
                }
              }
            }
          }
        }}
        valueFormatter={valueFormatter}
        subValueFormatter={subValueFormatter}
        yScaleValueFormatter={yScaleValueFormatter}
        timeFilter={timeFilter}
      />
    </>
  );
}

import { BaseTheme } from '@blockanalitica/ui/package/types/src/theme/baseTheme';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function sidebar(theme: BaseTheme): ComponentStyle {
  return {
    componentName: 'sidebar',
    baseStyle: {
      minWidth: `calc(220px + ${theme.space.s})`,
      width: `calc(220px + ${theme.space.s})`,
      position: ['fixed', 'fixed', 'sticky'],
      backgroundColor: theme.colors.black,
      padding: theme.space.s,
      borderRight: `1px solid ${theme.colors.onSurface}`
    }
  };
}

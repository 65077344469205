import queryString from 'query-string';

export interface ResponseError extends Error {
  status?: number;
}

export type apiFetcherArgs = {
  url: string;
  queryParams?: Record<string, string>;
  options?: RequestInit;
};

export default async function apiFetcher<T>({
  url,
  queryParams,
  options
}: apiFetcherArgs): Promise<T> {
  let qs: string = '';
  if (queryParams) {
    qs = queryString.stringify(queryParams, {
      skipNull: true,
      skipEmptyString: true
    });
    if (qs) {
      qs = `?${qs}`;
    }
  }

  const res = await fetch(
    // @ts-ignore
    `${import.meta.env.VITE_API_ENDPOINT}${url}${qs}`,
    options
  );

  if (!res.ok) {
    const error = new Error(
      'An error occurred while fetching the data.'
    ) as ResponseError;
    error.status = res.status;
    throw error;
  }

  return res.json();
}

import { Page } from '@/components';

import { useApi } from '@/api';
import { PageSkeleton } from '@/components';
import { useSearchParams } from '@/hooks';
import HistoricTabs from './HistoricTabs';
import WalletsTable from './WalletsTable';

export default function WalletsPage() {
  const ilk = 'LITE-PSM-USDC-A';
  const [daysAgo] = useSearchParams('days_ago', '1');
  const [data, error] = useApi({
    url: `/groups/stablecoins/ilks/${ilk}/gems/wallets/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }
  if (data) {
    return (
      <Page title="Wallets">
        <HistoricTabs ilk={'LITE-PSM-USDC-A'} />
        <WalletsTable ilk={ilk} />
      </Page>
    );
  }
  return <PageSkeleton />;
}

import { Page } from '@/components';
import { Flex, KpiSkeleton, Skeleton } from '@blockanalitica/ui';

export default function PageSkeleton() {
  return (
    <Page title={<Skeleton width={150} height="4" />}>
      <Flex gap={['m', 's']} flexDirection={['column', 'row']}>
        <KpiSkeleton />
        <KpiSkeleton />
      </Flex>
    </Page>
  );
}

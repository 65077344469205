import { useSearchParams } from '@/hooks';
import { useCallback } from 'react';
import { Text } from '@blockanalitica/ui';
import { styled } from 'styled-components';

// eslint-disable-next-line react-refresh/only-export-components
export enum TimeOptions {
  '1D' = '1',
  '7D' = '7',
  '30D' = '30'
}

type GlobalTimeFilterProps = {
  defaultValue?: TimeOptions;
};

const Wrapper = styled.div<{ active: boolean }>`
  padding: ${(props) => props.theme.space['xs']};
  cursor: pointer;

  ${(props) =>
    props.active
      ? `
      font-weight: 700;
      background-color: ${props.theme.colors.onSurface};
      border-radius: ${props.theme.sizes['-4']}`
      : null}
`;

export default function GlobalTimeFilter({
  defaultValue = TimeOptions['1D']
}: GlobalTimeFilterProps) {
  const [dataTimeFilter, setSearchParams] = useSearchParams(
    'days_ago',
    defaultValue
  );

  const onChangeDataFilter = useCallback(
    (key: keyof typeof TimeOptions) => {
      setSearchParams(TimeOptions[key]);
    },
    [setSearchParams]
  );

  return Object.keys(TimeOptions).map((key) => (
    <Wrapper
      key={key}
      active={dataTimeFilter === TimeOptions[key as keyof typeof TimeOptions]}
      onClick={() => onChangeDataFilter(key as keyof typeof TimeOptions)}>
      <Text variant="2">{key}</Text>
    </Wrapper>
  ));
}

import { Flex } from '@blockanalitica/ui';

export default function ExternalLink({ children = undefined, ...rest }) {
  return (
    <Flex
      as="a"
      display="flex"
      target="_blank"
      rel="noreferrer"
      gap="3xs"
      alignItems="center"
      {...rest}>
      {children}
    </Flex>
  );
}

import { Page } from '@/components';
import GemEventsTable from './GemEventsTable';
import HistoricTabs from './HistoricTabs';
import Kpis from './Kpis';

export default function ActivityPage() {
  return (
    <Page title="Activity">
      <Kpis />
      <HistoricTabs ilk="LITE-PSM-USDC-A" />
      <GemEventsTable ilk="LITE-PSM-USDC-A" />
    </Page>
  );
}

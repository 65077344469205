import { Theme } from '@blockanalitica/ui/package/types/src/theme/ThemeProvider';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function chartKpiValue(theme: Theme): ComponentStyle {
  return {
    componentName: 'chartKpiValue',
    baseStyle: {},
    variants: {
      single: {
        fontSize: theme.sizes['4']
      }
    }
  };
}

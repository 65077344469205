import { Box, BoxProps, Flex } from '@blockanalitica/ui';
import { useCallback } from 'react';
import styled, { css } from 'styled-components';

export interface ItemProps extends BoxProps {
  $active: boolean;
}

const Item = styled(Box)<ItemProps>`
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    mix-blend-mode: overlay;
  }

  ${(props) => css`
    padding: ${props.theme.space['xs']} ${props.theme.space['s']};
    color: ${props.theme.colors.muted};

    &:after {
      border-radius: ${props.theme.sizes['-1']};
    }

    &:hover {
      color: ${props.theme.colors.text};
      &:after {
        background: ${props.theme.colors.white25};
      }
    }
  `}

  ${(props) =>
    props.$active
      ? css`
          color: ${props.theme.colors.text};
          &:after {
            background: ${props.theme.colors.white70};
          }
        `
      : null}
`;

type TimeFilterProps = {
  options: Record<string, string>;
  activeOption: string | null;
  onChange: (value: string) => void;
};

export default function TimeFilterComponent({
  activeOption,
  options,
  onChange
}: TimeFilterProps) {
  const onChangeDataFilter = useCallback(
    (key: string) => {
      onChange(key);
    },
    [onChange]
  );

  return (
    <Flex gap="2xs">
      {Object.entries(options).map(([key, value]) => (
        <Item
          role="button"
          key={key}
          $active={activeOption === key}
          onClick={() => onChangeDataFilter(key)}>
          {value}
        </Item>
      ))}
    </Flex>
  );
}

import { useApi } from '@/api';
import { Flex, KpiCard, KpiSkeleton } from '@blockanalitica/ui';

export default function Kpis({ daysAgo }) {
  const [data, error] = useApi({
    url: '/groups/stablecoins/ilks/LITE-PSM-USDC-A/parameters/',
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Flex flexDirection="column" gap="m">
        <Flex flexDirection={['column', 'row']} gap={['m', 's']}>
          <KpiCard
            title="Volume 24h"
            data={data}
            field="total_volume"
            options={{
              currency: 'DAI',
              variants: {
                kpiHeader: 'large',
                kpiCardValue: 'large'
              }
            }}
          />
        </Flex>
      </Flex>
    );
  }

  return <KpiSkeleton />;
}

import { BaseTheme as Theme } from '@blockanalitica/ui/package/types/src/theme/baseTheme';
import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function kpiCardValue(theme: Theme): ComponentStyle {
  return {
    componentName: 'kpiCardValue',
    variants: {
      small: {
        fontSize: theme.sizes[2]
      },
      large: {
        fontSize: theme.sizes[5]
      }
    }
  };
}

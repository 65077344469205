import { ComponentStyle } from '@blockanalitica/ui/package/types/src/theme/types';

export function chartKpi(): ComponentStyle {
  return {
    componentName: 'chartKpi',
    baseStyle: {
      cursor: 'pointer'
    },
    variants: {
      disabled: {
        opacity: 0.4
      }
    }
  };
}

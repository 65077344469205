import { Sizes } from '@blockanalitica/ui/package/types/src/theme/sizes';

/* @link https://utopia.fyi/type/calculator?c=320,12,1.2,1240,14,1.2,10,5,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
const sizes: Sizes = {
  '-5': 'clamp(0.3014rem, 0.2839rem + 0.0874vi, 0.3516rem)',
  '-4': 'clamp(0.3617rem, 0.3407rem + 0.1048vi, 0.422rem)',
  '-3': 'clamp(0.434rem, 0.4089rem + 0.1258vi, 0.5064rem)',
  '-2': 'clamp(0.5208rem, 0.4906rem + 0.151vi, 0.6076rem)',
  '-1': 'clamp(0.625rem, 0.5888rem + 0.1812vi, 0.7292rem)',
  '0': 'clamp(0.75rem, 0.7065rem + 0.2174vi, 0.875rem)',
  '1': 'clamp(0.9rem, 0.8478rem + 0.2609vi, 1.05rem)',
  '2': 'clamp(1.08rem, 1.0174rem + 0.313vi, 1.26rem)',
  '3': 'clamp(1.296rem, 1.2209rem + 0.3757vi, 1.512rem)',
  '4': 'clamp(1.5552rem, 1.465rem + 0.4508vi, 1.8144rem)',
  '5': 'clamp(1.8662rem, 1.7581rem + 0.5409vi, 2.1773rem)',
  '6': 'clamp(2.2395rem, 2.1097rem + 0.6491vi, 2.6127rem)',
  '7': 'clamp(2.6874rem, 2.5316rem + 0.779vi, 3.1353rem)',
  '8': 'clamp(3.2249rem, 3.0379rem + 0.9347vi, 3.7623rem)',
  '9': 'clamp(3.8698rem, 3.6455rem + 1.1217vi, 4.5148rem)',
  '10': 'clamp(4.6438rem, 4.3746rem + 1.346vi, 5.4178rem)'
};

export default sizes;
